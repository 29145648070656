// Classes
import { DevelopmentTools } from '@/Classes/Static/DevelopmentTools'

// Components (.vue)
import CompanyLogo      from '@/Components/Extras/CompanyLogo/template.vue'
import DataTable        from '@/Components/Global/DataTable/template.vue'

// Components (Refs)
import { DataTableRef } from '@/Components/Global/DataTable/component'

// Dependencies
import VueMixins from 'vue-typed-mixins'

// Mixins
import MixinBase       from '@/Mixins/MixinBase'
import MixinComponent  from '@/Mixins/MixinComponent'
import MixinResponsive from '@/Mixins/MixinResponsive'

// Component Extend
const PopupTable = VueMixins(MixinBase, MixinComponent, MixinResponsive).extend({
	name: 'PopupTable',

	components: {
		DataTable,
		CompanyLogo
	},

	props: {
		numItems: { type: Number, default: 30 },
		show: Boolean,
	},

	data: function() {
		return {
			header: [] as Array<any>,
			states: {
				currentRow: undefined as any,
				showCustomHeader: false,
				showSelectButton: true,
				title: 'Place Holder'
			}
		}
	},

	computed: {
		_dataTable: function(): DataTableRef {
			return this.$refs.dataTable as DataTableRef
		}
	},

	methods: {
		_restoreStates: function() {
			// Reiniciar los valores propios del componente.
			this.setStates<PopupTableRef['states']>({ currentRow: undefined })

			// Aplicar 'Flag' temporal para prevenir comportamiento por Defecto al ejecutar 'clearSortBy'.
			const dataTable = this._dataTable
			dataTable.setStates<DataTableRef['states']>({ preventDefaultOnInputSearchClear: true })

			// Reiniciar los valores del componente <DataTable>.
			dataTable.resetFetchState()
			dataTable.clearSortBy()
			dataTable.clearData()
		},

		/* <=================|==============================|=================> */
		/* <=================| PUBLIC DECLARATION FUNCTIONS |=================> */
		/* <=================|==============================|=================> */

		getSelectVisible: function() {
			return this.states.showSelectButton === true ? '' : 'd-none'
		},

		getTitle: function() {
			return this.states.title
		},

		isEmpty: function() {
			return this.states.currentRow === undefined
		},

		setCustomHeader: function(...header: Array<object>) {
			this.header = header
		},

		setSelectVisible: function(bool: boolean) {
			this.states.showSelectButton = bool
		},

		setTitle: function(title: string) {
			this.states.title = title
		},

		/* <=================|=============================|==================> */
		/* <=================| EVENT DECLARATION FUNCTIONS |==================> */
		/* <=================|=============================|==================> */

		onPTButtonClick: function(key: string, row: object, button: Event) {
			DevelopmentTools.printWarn('[PopupTable]:onPTButtonClick event triggered')
			this.$emit('onPTButtonClick', key, row, button)
		},

		onPTClose: function() {
			DevelopmentTools.printWarn('[PopupTable]:onPTClose event triggered')
			this._restoreStates()
			this.$emit('onPTClose', 'PopupTable')
		},

		onPTCurrentSearchPaginationChanged: function(page: number, searchParams: any) {
			DevelopmentTools.printWarn('[PopupTable]:onPTCurrentSearchPaginationChanged event triggered')
			this.$emit('onPTCurrentSearchPaginationChanged', page, searchParams)
		},

		onPTPaginationChanged: function(page: number) {
			DevelopmentTools.printWarn('[PopupTable]:onPTPaginationChanged event triggered')
			this.$emit('onPTPaginationChanged', page)
		},

		onPTRefreshButtonClick: function() {
			DevelopmentTools.printWarn('[PopupTable]:onPTRefreshButtonClick event triggered')
			this.$emit('onPTRefreshButtonClick')
		},

		onPTSearchButtonClicked: function(searchParams: any) {
			DevelopmentTools.printWarn('[PopupTable]:onPTSearchButtonClicked event triggered')
			this.$emit('onPTSearchButtonClicked', searchParams)
		},

		onPTSelect: function() {
			DevelopmentTools.printWarn('[PopupTable]:onPTSelect event triggered')
			this.$emit('onPTSelect', this.states.currentRow)
			this._restoreStates()
		},

		onRowClick: function(item: object, index: number) {
			DevelopmentTools.printWarn('[PopupTable]:onRowClick():Event triggered')
			this.setStates<PopupTableRef['states']>({ currentRow: { item, index } })
			this.$emit('onRowClick', { item, index })
		},

		onRowDoubleClick: function(item: object, index: number) {
			DevelopmentTools.printWarn('[PopupTable]:onRowDoubleClick():Event triggered')
			this._restoreStates()
			this.$emit('onRowDoubleClick', { item, index })
		},

		onRowsUnselected: function() {
			DevelopmentTools.printWarn('[PopupTable]:onRowDoubleClick():Event triggered')
			this.setStates<PopupTableRef['states']>({ currentRow: undefined })
		}
	}
})

// Exports
export default PopupTable
export type PopupTableRef = InstanceType<typeof PopupTable>